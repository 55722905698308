<template>
  <el-dialog
    :visible.sync="addCourseShow"
    :before-close="cancel"
    :close-on-click-modal="false"
    title="新增课程"
    width="500px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="100px"
    >
      <el-form-item label="课程名称" prop="courseName">
        <el-input clearable v-model="dataForm.courseName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="课程编号" prop="courseCode">
        <el-input clearable v-model="dataForm.courseCode" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="课程分组" prop="groupId">
        <GlobalTreeSelect
          style="width: 330px"
          v-model="dataForm.groupId"
          filterable
          :nodeKey="nodeKey"
          :treeProps="defaultProps"
          :data="treeData"
          @input="_getBelongGroup"
          >
        </GlobalTreeSelect>
      </el-form-item>
      <el-form-item label="课程类型" prop="name">
        <el-select v-model="dataForm.courseTypeId" clearable  style="width: 330px" placeholder="请选择">
          <el-option
            v-for="item in courseTypeList"
            :key="item.id"
            :label="item.categoryName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课程描述">
        <el-input type="textarea" v-model="dataForm.description" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="是否发布" >
        <el-switch v-model="dataForm.status"> </el-switch>
      </el-form-item>
      <el-form-item label="可见范围" v-if="dataForm.status">
        <VisibilityRange ref="visibilityRange" :courseInfo="dataForm"/>
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button @click="submiAddGroup(1)">保存</el-button>
      <el-button type="primary" @click="submiAddGroup(2)">继续编辑</el-button>
    </div>
  </el-dialog>
</template>
<script>
import GlobalTreeSelect from '@/components/globalTreeSelect/index.vue'
import VisibilityRange from '@/components/visibilityRange/index.vue'
export default {
  components: { GlobalTreeSelect, VisibilityRange },

  props: {
    addCourseShow: {
      type: Boolean,
      default: false
    },
    treeData: {
      type: Array,
      default: () => []
    },
    defaultProps: {
      type: Object,
      default: () => {}
    },
    courseTypeList: {
      type: Array,
      default: () => []
    },
    nodeKey: {
      type: String,
      default: 'id'
    },
    pagesType: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dataForm: {
        courseName: '', // 课程名称
        courseTypeName: '',
        courseCode: '', // 课程编号
        group: '', // 课程分组
        parentId: '',
        description: '', // 课程描述
        useDeptIds: [],
        useUserIds: []
      },
      dataRule: {
        courseName: [
          {
            required: true,
            message: '请输入课程名称',
            trigger: 'blur'
          }
        ],
        courseCode: [
          {
            required: true,
            message: '请输入课程编号',
            trigger: 'blur'
          }
        ],
        groupId: [
          {
            required: true,
            message: '请输入课程分组',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
  },
  methods: {
    cancel () {
      this.$parent.addCourseShow = false
    },
    submiAddGroup (type) {
      // 分组保存
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          const useUserId = []
          const useDeptId = []
          if (this.dataForm.status) {
            // if (this.dataForm.isPublish && (!this.dataForm.useUserIds.length && !this.dataForm.useDeptIds.length)) {
            //   this.$message.error('请选择可见范围！')
            //   return
            // }

            this.dataForm.useUserIds.forEach(item => {
              useUserId.push(item.id)
            })
            this.dataForm.useDeptIds.forEach(item => {
              useDeptId.push(item.id)
            })
          }
          this.$emit('submiCourse', {
            butType: type,
            ...this.dataForm,
            useUserId: useUserId.length ? useUserId.toString() + ',' : '',
            useDeptId: useDeptId.length ? useDeptId.toString() + ',' : ''
          })
        }
      })
    },
    // 所属分组--取值
    _getBelongGroup (value) {
      this.dataForm.group = value || 0
      this.dataForm.parentId = value || 0
    }
  }
}
</script>
<style lang="scss" scoped>
.radius {
  width: 93%;
  background: #f6f7f9;
  border-radius: 4px 4px 4px 4px;
  padding: 12px;
  margin-top: 8px;
  .radiu-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 14px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
.tag-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.button-new-tag {
  padding-top: 0;
  padding-bottom: 0;
  width: 78px;
  height: 28px;
  text-align: center;
  padding: 8px 9px;
  margin-bottom: 10px;
}
.tag-item {
  margin-right: 10px;
  width: 78px;
  height: 28px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 10px;
}
.add-group {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  .add-group-btn {
    width: 102px;

    border: 1px solid #0089ff;
    font-size: 14px;
    color: #0089ff;
  }
}
.add-group-name {
  margin-bottom: 10px;
}
/deep/.el-dialog__body {
  max-height: 420px;
  overflow: auto;
}
</style>
